<template>
  <div v-if="disabled" type="text" :class="{'male':'text-primary','female':'text-error'}[type]">{{typeList.find(ele=>ele.value==type).label}}</div>
  <Select v-else v-model="type" @on-change="selectChange" transfer>
    <Option
      v-for="item in typeList"
      :value="item.value"
      :key="item.value"
    >{{ item.label }}</Option>
  </Select>
</template>

<script>
export default {
    props:{value: {},disabled:{
        default:false
    }},
    data(){
        return {
            typeList:[{
                value:'male',
                label:'男声'
            },{
                value:'female',
                label:'女声'
            },{
                value:'boy',
                label:'男童'
            },{
                value:'girl',
                label:'女童'
            }],
            type:''
        }
    },
    methods:{
        selectChange() {
            this.$emit("input", this.type);
        }
    },
    watch:{
        value: {
            handler() {
                this.type = this.value;
            },
            immediate: true
        }
    }

}
</script>

<style>

</style>